<template>
  <div id="add-hotel">
    <div class="container-fluid">
      <b-row>
        <b-col cols="4">
          <iq-card>
            <template v-slot:body>
              <div
                class="img-preview d-flex justify-content-center align-items-center"
              >
                <img
                  class="w-100 h-100"
                  v-if="imgPreview"
                  :src="imgPreview"
                  alt=""
                />
                <span v-else>{{ $t('img-preview') }}</span>
              </div>
              <div class="feild pt-3">
                <b-form-file
                  type="text"
                  @change="getImg($event)"
                  :placeholder="$t('upload-img')"
                  required
                ></b-form-file>
              </div>
              <div class="feild border rounded p-2">
                <b-form-checkbox :value="1" :unchecked-value="0" v-model="payload.smoking_policy" name="check-button"
                                 switch>
                  {{ $t('smoke') }}
                </b-form-checkbox>
              </div>
              <div class="feild border rounded p-2">
                <b-form-checkbox :value="1" :unchecked-value="0" v-model="payload.has_pool" name="check-button" switch>
                  {{ $t('pool') }}
                </b-form-checkbox>
              </div>
              <div class="feild border rounded p-2">
                <b-form-checkbox :value="1" :unchecked-value="0" v-model="payload.has_restaurant" name="check-button"
                                 switch>
                  {{ $t('restaurant') }}
                </b-form-checkbox>
              </div>
              <div class="feild border rounded p-2">
                <b-form-checkbox :value="1" :unchecked-value="0" v-model="payload.has_sauna" name="check-button" switch>
                  {{ $t('sauna') }}
                </b-form-checkbox>
              </div>
              <div class="feild border rounded p-2">
                <b-form-checkbox :value="1" :unchecked-value="0" v-model="payload.has_free_parking" name="check-button"
                                 switch>
                  {{ $t('free-parking') }}
                </b-form-checkbox>
              </div>
            </template>
          </iq-card>
        </b-col>
        <b-col cols="8">
          <iq-card>
            <template v-slot:headerTitle>
              <h4 class="card-title">{{ $t('add-hotel') }}</h4>
            </template>
            <template v-slot:body>
              <b-form class="d-grid grid-cols-2 gap-5">
                <b-row>
                  <b-col cols="12">
                    <div class="map-container mb-3 border p-1 rounded" >
                      <GmapPlaceInput class="g-map-search-input" @place_changed="handlePlaceChanged"/>
                      <GmapMap
                        :center="mapCenter"
                        :zoom="mapZoom"
                        style="width: 100%; height: 400px"
                        @click="onMapClick"
                      >
                        <GmapMarker
                          v-if="selectedLocation"
                          :position="selectedLocation"
                        />
                      </GmapMap>
                    </div>
                  </b-col>
                  <b-col cols="12">
                    <b-row>
                      <b-col cols="6">
                        <div class="feild">
                          <label for="">Lat</label>
                          <b-form-input
                            type="number"
                            min="0"
                            placeholder=""
                            v-model="payload.lat"
                            required
                          ></b-form-input>
                        </div>
                      </b-col>
                      <b-col cols="6">
                        <div class="feild">
                          <label for="">long</label>
                          <b-form-input
                            type="number"
                            min="0"
                            placeholder=""
                            v-model="payload.long"
                            required
                          ></b-form-input>
                        </div>
                      </b-col>
                    </b-row>
                  </b-col>
                  <b-col cols="6">
                    <div class="feild">
                      <label for="">{{ $t('name') }}</label>
                      <b-form-input
                        type="text"
                        placeholder=""
                        v-model="payload.title"
                        required
                      ></b-form-input>
                    </div>
                  </b-col>
                  <b-col cols="6">
                    <div class="feild">
                      <label for="">{{ $t('stars') }}</label>
                      <v-select v-model="payload.stars" placeholder="" :options="[1,2,3,4,5]" :reduce="(option)=>option"
                                label="option"/>
                    </div>
                  </b-col>
                  <b-col cols="6">
                    <div class="feild">
                      <label for="">{{ $t('city') }}</label>
                      <v-select v-model="payload.city_id" :options="cities.data" :reduce="(option)=>option.id"
                                :label="`${$i18n.locale}_name`" @search="handleCitySearch" />
                    </div>
                  </b-col>
                  <b-col cols="6">
                    <div class="feild">
                      <label for="">{{ $t('address') }}</label>
                      <b-form-input
                        type="text"
                        placeholder=""
                        v-model="payload.address"
                        required
                      ></b-form-input>
                    </div>
                  </b-col>
                  <b-col cols="6">
                    <div class="feild">
                      <label for="">{{ $t('floors') }}</label>
                      <b-form-input
                        type="number"
                        min="1"
                        placeholder=""
                        v-model="payload.number_of_floors"
                        required
                      ></b-form-input>
                    </div>
                  </b-col>
                  <b-col cols="6">
                    <div class="feild">
                      <label for="">{{ $t('start-price') }}</label>
                      <b-form-input
                        type="number"
                        min="0"
                        placeholder=""
                        v-model="payload.start_price"
                        required
                      ></b-form-input>
                    </div>
                  </b-col>
                  <b-col cols="6">
                    <div class="feild">
                      <label for="">{{ $t('end-price') }}</label>
                      <b-form-input
                        type="number"
                        min="0"
                        placeholder=""
                        v-model="payload.end_price"
                        required
                      ></b-form-input>
                    </div>
                  </b-col>
                  <b-col v-if="!$route.params.id" cols="6">
                    <div class="feild">
                      <label for="">{{ $t('user-name') }}</label>
                      <b-form-input
                        type="text"
                        placeholder=""
                        v-model="payload.name"
                        required
                      ></b-form-input>
                    </div>
                  </b-col>
                  <b-col v-if="!$route.params.id" cols="6">
                    <div class="feild">
                      <label for="">{{ $t('email') }}</label>
                      <b-form-input
                        type="text"
                        placeholder=""
                        v-model="payload.email"
                        required
                      ></b-form-input>
                    </div>
                  </b-col>
                  <b-col v-if="!$route.params.id" cols="6">
                    <div class="feild">
                      <label for="">{{ $t('password') }}</label>
                      <b-form-input
                        type="text"
                        placeholder=""
                        v-model="payload.password"
                        required
                      ></b-form-input>
                    </div>
                  </b-col>
                  <b-col v-if="!$route.params.id" cols="6">
                    <div class="feild">
                      <label for="">{{ $t('phone') }}</label>
                      <b-form-input
                        type="text"
                        placeholder=""
                        v-model="payload.phone"
                        required
                      ></b-form-input>
                    </div>
                  </b-col>
                  <b-col cols="12" v-if="$route.params.id">
                    <b-row>
                      <b-col cols="12" class="mb-2">
                        <label for="">{{ $t('ar-desx') }} </label>
                        <b-form-textarea
                          type="text"
                          rows="4"
                          v-model="payload.ar_description"
                          required
                        ></b-form-textarea>
                      </b-col>
                      <b-col cols="12" class="mb-2">
                        <label for="">{{ $t('ar-desx') }} </label>
                        <b-form-textarea
                          type="text"
                          rows="4"
                          v-model="payload.kr_description"
                          required
                        ></b-form-textarea>
                      </b-col>
                      <b-col cols="12" class="mb-2">
                        <label for="">{{ $t('en-desx') }} </label>
                        <b-form-textarea
                          type="text"
                          rows="4"
                          v-model="payload.en_description"
                          required
                        ></b-form-textarea>
                      </b-col>
                    </b-row>
                  </b-col>
                </b-row>
              </b-form>
              <div class="form-controll d-flex justify-content-end py-3">
                <b-button variant="primary" @click="submit" size="lg">{{ $t('save') }}</b-button>
              </div>
            </template>
          </iq-card>

        </b-col>
      </b-row>
    </div>
  </div>
</template>
<script>
import { HotelType } from '@/enums'
import { gmapApi } from 'vue2-google-maps'
import debounce from 'debounce'

export default {
  name: 'hotels',
  data () {
    return {
      mapCenter: {
        lat: 33.3101565,
        lng: 44.4276196
      }, // initial map center
      mapZoom: 15, // initial map zoom level
      selectedLocation: null, // selected location coordinates
      selectedAddress: '', // selected address
      geocoder: null,

      show_land_img: false,
      land_img_path: '',
      imgPreview: '',
      payload: {
        title: '',
        stars: '',
        long: '',
        lat: '',
        start_price: '',
        end_price: '',
        smoking_policy: 0,
        has_pool: 0,
        has_restaurant: 0,
        has_sauna: 0,
        has_free_parking: 0,
        city_id: '',
        number_of_floors: 1,
        name: '',
        email: '',
        image: '',
        password: '',
        address: ''
      }
    }
  },
  computed: {
    HotelType () {
      return HotelType
    },
    gmapApi () {
      return gmapApi
    }
  },
  methods: {
    onMapClick (event) {
      this.selectedLocation = {
        lat: event.latLng.lat(),
        lng: event.latLng.lng()
      }
      this.payload.lat = event.latLng.lat()
      this.payload.long = event.latLng.lng()
      this.getAddressFromCoords(event.latLng)
    },
    getAddressFromCoords (latLng) {
      const Geocder = gmapApi().maps.Geocoder
      const pi = new Geocder()
      pi.geocode({ location: latLng }, (results, status) => {
        if (status === 'OK') {
          if (results[0]) {
            this.payload.address = results[0].formatted_address
          }
        }
      })
    },
    getImg (event) {
      this.imgPreview = URL.createObjectURL(event.target.files[0])
      this.payload.image = event.target.files[0]
    },
    convertBooleansToNumbers (obj) {
      for (const key in obj) {
        if (typeof obj[key] === 'boolean') {
          // Convert boolean to number (true -> 1, false -> 0)
          obj[key] = obj[key] ? 1 : 0
        }
      }
      return obj
    },
    submit (bvModalEvent) {
      bvModalEvent.preventDefault()
      if (!this.payload.id) {
        this.addHotels(this.returnData(this.convertBooleansToNumbers(this.payload))).then(() => {
          this.payload = this.resetObject(this.payload)
          this.$router.push('/hotels')
        })
      } else {
        this.payload._method = 'PUT'
        this.updateHotels({
          id: this.$route.params.id,
          payload: this.returnData(this.convertBooleansToNumbers(this.payload))
        }).then(() => {
          this.payload = this.resetObject(this.payload)
          this.$router.push('/hotels')
        })
      }
    },
    handlePlaceChanged (v) {
      this.mapCenter.lat = v.geometry.location.lat()
      this.mapCenter.lng = v.geometry.location.lng()
    },
    handleCitySearch: debounce(function (city) {
      if (!city || city.length < 1) return
      this.getCities({
        take: 18,
        page: 1,
        [this.$i18n.locale + '_name']: city,
        governorate_id: ''
      })
    }, 1000)
  },
  mounted () {
    if (this.$route.params.id) {
      this.getHotel(this.$route.params.id).then(() => {
        Object.assign(this.payload, this.hotel)
        this.selectedLocation = {
          lat: parseFloat(this.payload.lat),
          lng: parseFloat(this.payload.long)
        }
        this.payload.image = ''
        this.imgPreview = this.hotel.image
        this.getCities({
          governorate_id: this.hotel.governorate_id,
          take: 100,
          page: 1,
          ar_name: '',
          en_name: '',
          kr_name: ''
        })
      })
    } else {
      this.getCities()
    }
  }
}
</script>
<style>
.add-new-img {
  font-size: 18px;
  font-weight: bold;
  color: #ccc;
  height: 200px;
  border: 2px dashed #ccc;
  cursor: pointer;
}

.img-preview {
  height: 200px;
  border: 2px dashed #ccc;
}

.img-preview img {
  object-fit: contain;
}
.g-map-search-input {
  width: 100%;
  margin: 10px 0;
}
.g-map-search-input input {
  width: 100%;
}
</style>
